import { combineReducers } from 'redux';
import categories from './categories';
import blog from './blog';
import auth from './auth';
import actividades from './actividades';
import dojos from "./dojos";
export default combineReducers({
    categories,
    blog,
    actividades,
    auth,
    dojos,
})