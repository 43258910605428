import {
  GET_DOJO_SUCCESS,
  GET_DOJO_FAIL,
  GET_AUTHOR_DOJO_LIST_SUCCESS,
  GET_AUTHOR_DOJO_LIST_FAIL,
  GET_DOJO_LIST_SUCCESS,
  GET_DOJO_LIST_FAIL,
  GET_DOJO_OPTIONS_SUCCESS,
  GET_DOJO_OPTIONS_FAIL,
  GET_ZONA_OPTIONS_SUCCESS,
  GET_ZONA_OPTIONS_FAIL,
  GET_GRADO_OPTIONS_SUCCESS,
  GET_GRADO_OPTIONS_FAIL,
  GET_BRANCH_OPTIONS_SUCCESS,
  GET_BRANCH_OPTIONS_FAIL,
  GET_OPERATOR_OPTIONS_SUCCESS,
  GET_OPERATOR_OPTIONS_FAIL,
  GET_DIRECTOR_OPTIONS_SUCCESS,
  GET_DIRECTOR_OPTIONS_FAIL,
} from "../actions/dojos/types";

const initialState = {
  dojo_list: null,
  author_dojo_list: null,
  error: null,
  count: null,
  next: null,
  post: null,
  previous: null,
  cargoOptions: null || [],
  zonaOptions: null || [],
  gradoOptions: null || [],
  branchOptions: null || [],
  operatorOptions: null || [],
  directorOptions: null || [],
};

export default function dojos(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DOJO_LIST_SUCCESS:
      return {
        ...state,
        dojo_list: payload.results.posts,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_DOJO_LIST_FAIL:
      return {
        ...state,
        dojo_list: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_AUTHOR_DOJO_LIST_SUCCESS:
      return {
        ...state,
        author_dojo_list: payload.results.posts,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_AUTHOR_DOJO_LIST_FAIL:
      return {
        ...state,
        author_dojo_list: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_DOJO_SUCCESS:
      return {
        ...state,
        post: payload.posts,
      };
    case GET_DOJO_FAIL:
      return {
        ...state,
        post: null,
      };
    case GET_DOJO_OPTIONS_SUCCESS:
      return {
        ...state,
        cargoOptions: payload.cargoOptions || [],
      };
    case GET_DOJO_OPTIONS_FAIL:
      return {
        ...state,
        cargoOptions: [],
        error: action.payload || { message: action.payload.message },
      };
    case GET_ZONA_OPTIONS_SUCCESS:
      return {
        ...state,
        zonaOptions: payload.locationOptions  || [],
      };
    case GET_ZONA_OPTIONS_FAIL:
      return {
        ...state,
        zonaOptions: [],
        error: action.payload || { message: action.payload.message },
      };
    case GET_GRADO_OPTIONS_SUCCESS:
      return {
        ...state,
        gradoOptions: payload.GradOptions || [],
      };
    case GET_GRADO_OPTIONS_FAIL:
      return {
        ...state,
        gradoOptions: [],
        error: action.payload || { message: action.payload.message },
      };
    case GET_BRANCH_OPTIONS_SUCCESS:
      return {
        ...state,
        branchOptions: payload.BrOptions || [],
      };
    case GET_BRANCH_OPTIONS_FAIL:
      return {
        ...state,
        branchOptions: [],
        error: action.payload || { message: action.payload.message },
      };
    case GET_OPERATOR_OPTIONS_SUCCESS:
      return {
        ...state,
        operatorOptions: payload.OpOptions || [],
      };
    case GET_OPERATOR_OPTIONS_FAIL:
      return {
        ...state,
        operatorOptions: [],
        error: action.payload || { message: action.payload.message },
      };
    case GET_DIRECTOR_OPTIONS_SUCCESS:
      return {
        ...state,
        directorOptions: payload.DirOptions || [],
      };
    case GET_DIRECTOR_OPTIONS_FAIL:
      return {
        ...state,
        directorOptions: [],
        error: action.payload || { message: action.payload.message },
      };
    default:
      return state;
  }
}
