export const GET_DOJO_LIST_SUCCESS = 'GET_DOJO_LIST_SUCCESS';
export const GET_DOJO_LIST_FAIL = 'GET_DOJO_LIST_FAIL';

export const GET_AUTHOR_DOJO_LIST_SUCCESS = 'GET_AUTHOR_DOJO_LIST_SUCCESS';
export const GET_AUTHOR_DOJO_LIST_FAIL = 'GET_AUTHOR_DOJO_LIST_FAIL';

export const GET_DOJO_LIST_CATEGORIES_SUCCESS = 'GET_DOJO_LIST_CATEGORIES_SUCCESS';
export const GET_DOJO_LIST_CATEGORIES_FAIL = 'GET_DOJO_LIST_CATEGORIES_FAIL';

export const GET_DOJO_SUCCESS = 'GET_DOJO_SUCCESS';
export const GET_DOJO_FAIL = 'GET_DOJO_FAIL';

export const GET_SEARCH_DOJO_SUCCESS = 'GET_SEARCH_DOJO_SUCCESS';
export const GET_SEARCH_DOJO_FAIL = 'GET_SEARCH_DOJO_FAIL';

export const GET_DOJO_OPTIONS_SUCCESS = 'GET_DOJO_OPTIONS_SUCCESS';
export const GET_DOJO_OPTIONS_FAIL = 'GET_DOJO_OPTIONS_FAIL';

export const GET_GRADO_OPTIONS_FAIL = 'GET_GRADO_OPTIONS_FAIL';
export const GET_GRADO_OPTIONS_SUCCESS = 'GET_GRADO_OPTIONS_SUCCESS';

export const GET_ZONA_OPTIONS_SUCCESS = 'GET_ZONA_OPTIONS_SUCCESS';
export const GET_ZONA_OPTIONS_FAIL = 'GET_ZONA_OPTIONS_FAIL'; 

export const GET_BRANCH_OPTIONS_FAIL = 'GET_BRANCH_OPTIONS_FAIL';
export const GET_BRANCH_OPTIONS_SUCCESS = 'GET_BRANCH_OPTIONS_SUCCESS'; 

export const GET_OPERATOR_OPTIONS_FAIL = 'GET_OPERATOR_OPTIONS_FAIL';
export const GET_OPERATOR_OPTIONS_SUCCESS = 'GET_OPERATOR_OPTIONS_SUCCESS';

export const GET_DIRECTOR_OPTIONS_FAIL = 'GET_DIRECTOR_OPTIONS_FAIL';
export const GET_DIRECTOR_OPTIONS_SUCCESS = 'GET_DIRECTOR_OPTIONS_SUCCESS';


export const GET_DOJO_LOADING = 'GET_DOJO_LOADING';
