export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const GET_ACTIVIDADES_LIST_SUCCESS = 'GET_ACTIVIDADES_LIST_SUCCESS';
export const GET_ACTIVIDADES_LIST_FAIL = 'GET_ACTIVIDADES_LIST_FAIL';

export const GET_AUTHOR_ACTIVIDADES_LIST_SUCCESS = 'GET_AUTHOR_ACTIVIDADES_LIST_SUCCESS';
export const GET_AUTHOR_ACTIVIDADES_LIST_FAIL = 'GET_AUTHOR_ACTIVIDADES_LIST_FAIL';

export const GET_ACTIVIDADES_LIST_CATEGORIES_SUCCESS = 'GET_ACTIVIDADES_LIST_CATEGORIES_SUCCESS';
export const GET_ACTIVIDADES_LIST_CATEGORIES_FAIL = 'GET_ACTIVIDADES_LIST_CATEGORIES_FAIL';

export const GET_ACTIVIDADES_SUCCESS = 'GET_ACTIVIDADES_SUCCESS';
export const GET_ACTIVIDADES_FAIL = 'GET_ACTIVIDADES_FAIL';

export const GET_SEARCH_ACTIVIDADES_SUCCESS = 'GET_SEARCH_ACTIVIDADES_SUCCESS';
export const GET_SEARCH_ACTIVIDADES_FAIL = 'GET_SEARCH_ACTIVIDADES_FAIL';

export const GET_RELATED_POSTS_SUCCESS = 'GET_RELATED_POSTS_SUCCESS';
export const GET_RELATED_POSTS_FAIL = 'GET_RELATED_POSTS_FAIL';