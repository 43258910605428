import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

// Importación dinámica de componentes
const Error404 = lazy(() => import("containers/errors/Error404"));
const Home = lazy(() => import("containers/pages/Home"));
const Blog = lazy(() => import("containers/pages/blog/Blog"));
const Dashboard = lazy(() => import("containers/pages/Dashboard"));
const ResetPassword = lazy(() => import("containers/auth/ResetPassword"));
const ResetPasswordConfirm = lazy(() => import("containers/auth/ResetPasswordConfirm"));
const EditPost = lazy(() => import("containers/pages/blog/EditPost"));

// const Eventos = lazy(() => import("containers/pages/eventos/Eventos"));
// const Edit = lazy(() => import("containers/pages/eventos/Edit"));
// const Dojos = lazy(() => import("containers/pages/dojos/Dojos"));
// const EditDojo = lazy(() => import("containers/pages/dojos/EditDojo"));

// Componente de carga
const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="w-16 h-16 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
    </div>
  );
};

function AppRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/* Error Display */}
        <Route path="*" element={<Error404 />} />

        {/* Home Display */}
        <Route path="/" element={<Home />} />

        {/* Auth Routes */}
        <Route path="/forgot_password" element={<ResetPassword />} />
        <Route
          path="/password/reset/confirm/:uid/:token"
          element={<ResetPasswordConfirm />}
        />

        {/* Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />

        {/* Blog Routes */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<EditPost />} />

        {/* Eventos Routes */}
        {/* <Route path="/actividades" element={<Eventos />} />
        <Route path="/actividades/:slug" element={<Edit />} /> */}

        {/* Dojos Routes */}
        {/* <Route path="/Dojos" element={<Dojos />} />
        <Route path="/Dojos/:slug" element={<EditDojo />} /> */}
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
