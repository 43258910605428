import {
  GET_ACTIVIDADES_LIST_SUCCESS,
  GET_ACTIVIDADES_LIST_FAIL,
  GET_ACTIVIDADES_SUCCESS,
  GET_ACTIVIDADES_FAIL,
  GET_ACTIVIDADES_LIST_CATEGORIES_SUCCESS,
  GET_ACTIVIDADES_LIST_CATEGORIES_FAIL,
  GET_SEARCH_ACTIVIDADES_SUCCESS,
  GET_SEARCH_ACTIVIDADES_FAIL,
  GET_AUTHOR_ACTIVIDADES_LIST_SUCCESS,
  GET_AUTHOR_ACTIVIDADES_LIST_FAIL
} from '../actions/actividades/types';

const initialState = {
  actividades_list: null,
  author_actividades_list: null,
  actividades_list_category: null,
  filtered_posts: null,
  post: null,
  count: null,
  next: null,
  previous: null
};

export default function actividades(state = initialState, action) {
  const { type, payload } = action;

  switch(type) {
      case GET_ACTIVIDADES_LIST_CATEGORIES_SUCCESS:
          return {
              ...state,
              actividades_list_category: payload.results.posts,
              count: payload.count,
              next: payload.next,
              previous: payload.previous,
          }
      case GET_ACTIVIDADES_LIST_CATEGORIES_FAIL:
          return {
              ...state,
              actividades_list_category: null,
              count: null,
              next: null,
              previous: null,
          }
      case GET_ACTIVIDADES_LIST_SUCCESS:
          return {
              ...state,
              actividades_list: payload.results.posts,
              count: payload.count,
              next: payload.next,
              previous: payload.previous,
          }
      case GET_ACTIVIDADES_LIST_FAIL:
          return {
              ...state,
              actividades_list: null,
              count: null,
              next: null,
              previous: null,
          }
      case GET_AUTHOR_ACTIVIDADES_LIST_SUCCESS:
          return {
              ...state,
              author_actividades_list: payload.results.posts,
              count: payload.count,
              next: payload.next,
              previous: payload.previous,
          }
      case GET_AUTHOR_ACTIVIDADES_LIST_FAIL:
          return {
              ...state,
              author_actividades_list: null,
              count: null,
              next: null,
              previous: null,
          }
      case GET_ACTIVIDADES_SUCCESS:
          return {
              ...state,
              post: payload.post
          }
      case GET_ACTIVIDADES_FAIL:
          return {
              ...state,
              post: null
          }
      case GET_SEARCH_ACTIVIDADES_SUCCESS:
          return {
              ...state,
              filtered_posts: payload.results.filtered_posts,
              count: payload.count,
              next: payload.next,
              previous: payload.previous,
          }
      case GET_SEARCH_ACTIVIDADES_FAIL:
          return {
              ...state,
              filtered_posts: null,
              count: null,
              next: null,
              previous: null,
          }
      default:
          return state
  }
}